import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./index.css";
//import "primereact/resources/themes/lara-light-cyan/theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { RequestForm } from "./screens/RequestForm.js";
import { Callback } from "./screens/Callback.js";

import { CallbackApprove } from "./screens/CallbackApprove.js";
import { CallbackCancel } from "./screens/CallbackCancel.js";
import { CallbackDecline } from "./screens/CallbackDecline.js";
import { ChakraProvider } from "@chakra-ui/react";

//import "@fontsource/raleway/400.css";
//import "@fontsource/open-sans/700.css";

import themes from "./components/Theme.js";
import PerceptionNote from "./screens/PerceptionNote.js";

import "primereact/resources/themes/lara-light-cyan/theme.css";

import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from "@chakra-ui/react";

import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

const {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
} = chakraTheme.components;

const theme = extendBaseTheme({
  components: {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
  },
});

const RedirectToHtml = ({ targetUrl }) => {
  useEffect(() => {
    window.location.href = targetUrl;
  }, [targetUrl]);

  return null; // Aucun rendu nécessaire
};

function App() {
  return (
    <PrimeReactProvider>
      <ChakraProvider theme={themes}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RequestForm />} />
            <Route path="/note" element={<PerceptionNote />} />
            <Route
              path="/callback_approve"
              element={<RedirectToHtml targetUrl="/approve.html" />}
            />
            <Route
              path="/callback_decline"
              element={<RedirectToHtml targetUrl="/decline.html" />}
            />
            <Route
              path="/callback_cancel"
              element={<RedirectToHtml targetUrl="/cancel.html" />}
            />
            {/*  <Route path="/formreview" element={<FormReview />} />*/}
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </PrimeReactProvider>
  );
}

export default App;
