import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

import { MdPayment } from "react-icons/md";
import { FaDownload } from "react-icons/fa";

import themes from "../components/Theme.js";
import {
  initiatePayment,
  createAndDownloadPDF,
} from "../services/PaymentService.js";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  SimpleGrid,
  Button,
  Container,
  Divider,
  Select,
  Image,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import html2canvas from "html2canvas";

const PerceptionNote = () => {
  const location = useLocation();
  const {
    code,
    firstName,
    postName,
    surName,
    birthPlace,
    birthDate,
    maritalStatus,
    sex,
    profession,
    province,
    territory,
    sector,
    village,
    ethnic,
    identity,
    livingStreetNumber,
    livingStreet,
    livingProvince,
    livingCity,
    livingTownship,
    phone,
    reason,
    fatherName,
    motherName,
    fatherNationality,
    motherNationality,
    issueDate,
  } = location.state || {};

  const pay = () => {
    initiatePayment();
  };

  const noteRef = useRef();

  const downloadNote = async () => {
    const element = noteRef.current;
    const name = firstName;
    const canvas = await html2canvas(element);
    createAndDownloadPDF(canvas, name);
  };

  return (
    <div style={{ backgroundColor: "#ebf3fa", height: "100%" }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        pb={100}
      >
        <Box maxW="container.sm" bg="white" mt={15} pb={100}>
          <Container
            ref={noteRef}
            fontFamily={themes.fonts.body}
            pt={8}
            pl={50}
            pr={50}
          >
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <Image height={100} src="./dcn-logo.png" />
              </div>
              <div style={{ marginLeft: "15px" }}>
                <h3 style={{ fontSize: 15 }}>MINISTERE DE LA JUSTICE</h3>
                <h1 style={{ fontSize: 20 }}>
                  DEMANDE DE CERTIFICAT DE NATIONALITE
                </h1>
              </div>
            </Box>

            <Box style={{ display: "flex", marginTop: "10px" }}>
              <Divider flex="1" borderColor="blue" borderWidth={6} />
              <Divider flex="1" borderColor="red" borderWidth={6} />
              <Divider flex="1" borderColor="yellow" borderWidth={6} />
            </Box>

            <h1
              fontSize={40}
              style={{ textAlign: "center", fontSize: 24, marginTop: "10px" }}
            >
              NOTE DE PERCEPTION
            </h1>

            <SimpleGrid mt={10} columns={[1, null, 1]} spacing="40px">
              <Box>
                <h2>EMETTEUR</h2>
                Nom: Ministere de la justice <br />
                Adresse: Palais de Justice, Place de l'Indépendance -
                Kinshasa/Gombe
                <br />
                Téléphone : 243 814 168 325
                <br />
                Email : info@justice.gouv.cd
                <br />
              </Box>
              <Box>
                <h2>DESTINATAIRE</h2>
                Nom: {firstName} {postName} {surName}
                <br />
                Adresse :{livingStreetNumber} {livingStreet} {livingCity}{" "}
                {livingTownship} {livingProvince},
              </Box>
              <Box>
                <h2>DETAILS DE LA NOTE</h2>
                Numero: {code}
                <br />
                Date d'emission: {issueDate}
                <br />
                Montant: 140 000 CDF
                <br />
              </Box>
              <Box></Box>
            </SimpleGrid>
          </Container>
          <Container>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={10}
              style={{ flexDirection: "row" }}
            >
              <Button
                colorScheme="blue"
                bgColor={"#0095c9"}
                _hover={{ bgColor: "#db3832" }}
                size="md"
                fontFamily={themes.fonts.body}
                top={10}
                onClick={() => {
                  downloadNote();
                }}
              >
                <FaDownload />
                Télécharger
              </Button>
              <Button
                colorScheme="blue"
                bgColor={"#0095c9"}
                _hover={{ bgColor: "#db3832" }}
                size="md"
                fontFamily={themes.fonts.body}
                top={10}
                onClick={() => {
                  pay();
                }}
              >
                <MdPayment />
                Payer en ligne
              </Button>
            </SimpleGrid>
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default PerceptionNote;
