import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  SimpleGrid,
  Text,
  Image,
  Divider,
  Flex,
} from "@chakra-ui/react";

//import {ArticleZone} from '../components/ArticleZone.js';
//import {Navbar} from '../components/Navbar.js';

export const Banner = () => {
  const imageUrl = "./pj.jpg";
  return (
    <div>
      <Box
        bgColor={"#070961"}
        color={"white"}
        minHeight={180}
        textAlign={"center"}
        padding={5}
        zIndex={1}
        overflow="hidden"
        style={{
          background: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Image height={120} src="./minjustice.png" />
        </Box>
        <Text fontSize={20} mt={15}>
          FICHE DE RENSEIGNEMENTS POUR DEMANDE DE CERTIFICAT DE NATIONALITE
          CONGOLAISE
        </Text>
        <Box style={{ display: "flex" }} mt={15}>
          <Divider flex="1" backgroundColor="#0095c9" h={2} opacity={1} />
          <Divider flex="1" backgroundColor="#fff24b" h={2} opacity={1} />
          <Divider flex="1" backgroundColor="#db3832" h={2} opacity={1} />
        </Box>
      </Box>
    </div>
  );
};
