import React, { useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const initiatePayment = async () => {
  console.log(process.env.REACT_APP_APPROVE_URL);
  console.log("PAAAAAAYEMENT");
  /*const body = {
      authorization: "VOTRE_TOKEN", // Remplacez par votre token réel
      merchant: "VOTRE_NOM_MARCHAND", // Remplacez par votre nom marchand réel
      reference: "VOTRE_REFERENCE", // Remplacez par votre référence réelle
      amount: "VOTRE_MONTANT", // Remplacez par le montant réel
      currency: "VOTRE_DEVISE", // Remplacez par la devise réelle
      description: "VOTRE_DESCRIPTION", // Remplacez par la description réelle
      callback_url: "VOTRE_CALLBACK_URL", // Remplacez par votre URL de callback
      approve_url: "VOTRE_APPROVE_URL", // Remplacez par votre URL d'approbation
      cancel_url: "VOTRE_CANCEL_URL", // Remplacez par votre URL d'annulation
      decline_url: "VOTRE_DECLINE_URL", // Remplacez par votre URL de refus
      home_url: "VOTRE_HOME_URL", // Remplacez par votre URL d'accueil
    };*/

  const body = {
    authorization:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJcL2xvZ2luIiwicm9sZXMiOlsiTUVSQ0hBTlQiXSwiZXhwIjoxNzU2OTA5NTc3LCJzdWIiOiI4MjQxYzE0YjQ1OTdlN2NhNTYwMWM3YTMxYmFhNGQwNiJ9.0A7tyaArfybTOL1kozJEC0ed6uFe57ZkGyQS5NnVL1A",
    merchant: "TAJIRI",
    reference: "postman",
    amount: "200",
    currency: "CDF",
    description:
      "Paiement des frais de demande de certification de nationalié Congolaise ",
    callback_url: process.env.REACT_APP_CALLBACK_URL,
    approve_url: process.env.REACT_APP_APPROVE_URL,
    cancel_url: process.env.REACT_APP_CANCEL_URL,
    decline_url: process.env.REACT_APP_DECLINE_URL,
    home_url: process.env.REACT_APP_BASE_URL,
  };

  try {
    const response = await axios.post(
      "https://cardpayment.flexpay.cd/api/rest/v1/vpos/ask",
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data;

    if (data.code === "0") {
      // Rediriger l'utilisateur vers l'URL retournée
      window.location.href = data.url;
    } else {
      alert(`Erreur: ${data.message}`);
    }
  } catch (error) {
    console.error("Erreur lors de la requête:", error);
    alert("Erreur lors de la requête");
  } finally {
    //setLoading(false);
  }
};

const createAndDownloadPDF = async (canvas, name) => {
  //const element = printRef.current;
  const data = canvas.toDataURL("image/png");
  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  pdf.save(name + "-" + "pdf");
};

const createAndDownloadPDF2 = async (canvas, name) => {
  // Augmenter la résolution du canvas
  const scale = 2; // Facteur de mise à l'échelle pour augmenter la qualité
  const originalWidth = canvas.width;
  const originalHeight = canvas.height;
  const scaledCanvas = document.createElement("canvas");
  scaledCanvas.width = originalWidth * scale;
  scaledCanvas.height = originalHeight * scale;
  const ctx = scaledCanvas.getContext("2d");

  ctx.scale(scale, scale);
  ctx.drawImage(canvas, 0, 0);

  const data = scaledCanvas.toDataURL("image/png");
  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  // Définir les marges (en points)
  const margin = 10;
  const contentWidth = pdfWidth - 2 * margin;
  const contentHeight =
    (imgProperties.height * contentWidth) / imgProperties.width;

  pdf.addImage(data, "PNG", margin, margin, contentWidth, contentHeight);
  pdf.save(name + "-" + "pdf");
};

export { initiatePayment, createAndDownloadPDF };
