import { extendTheme } from "@chakra-ui/react";
import { color } from "framer-motion";
const theme = extendTheme({
  fonts: {
    heading: `Corbel, sans-serif`,
    body: `'Cooper Hewitt Book', sans-serif`,
  },
  fontSize: {
    body: `18px`,
  },
  color: {
    error: "red",
  },
});

export default theme;
