import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const schemaStep1 = Yup.object().shape({
  nom: Yup.string().required("Le nom est obligatoire"),
  postNom: Yup.string().required("Le postnom est obligatoire"),
  prenom: Yup.string().required("Le prénom est obligatoire"),
  lieuNaiss: Yup.string().required("Le lieu de naissance est obligatoire"),
  dateNaiss: Yup.date("La date doit avoir le bon format")
    .required("La date de naissance est obligatoire")
    .typeError("La date doit avoir le bon format"),
  etatCivil: Yup.string().required("L'état civil est obligatoire"),
  gender: Yup.string().required("Le genre est obligatoire"),
  profession: Yup.string().required("La profession est obligatoire"),
});

const schemaStep2 = Yup.object().shape({
  province: Yup.string().required("La province est obligatoire"),
  territoire: Yup.string().required("Le territoire est obligatoire"),
  secteur: Yup.string().required("Le secteur est obligatoire"),
  village: Yup.string().required("Le village est obligatoire"),
  ethnie: Yup.string().required("L'ethnie est obligatoire"),
  piece: Yup.string().required("La pièce d'identité est obligatoire"),
});

const schemaStep3 = Yup.object().shape({
  file: Yup.mixed().required("Le fichier est obligatoire"),
  number: Yup.string().required("Le numéro est obligatoire"),
  avenue: Yup.string().required("L'avenue est obligatoire"),
  provinceAdresse: Yup.string().required("La province est obligatoire"),
  villeAdresse: Yup.string().required("La ville est obligatoire"),
  communeAdresse: Yup.string().required("La commune est obligatoire"),
  tel: Yup.string().required("Le numéro de téléphone est obligatoire"),
  motif: Yup.string().required("Le motif est obligatoire"),
});

const schemaStep4 = Yup.object().shape({
  pere: Yup.string().required("Le nom du père est obligatoire"),
  nationalitePere: Yup.string().required(
    "La nationalité du père est obligatoire"
  ),
  mere: Yup.string().required("Le nom de la mère est obligatoire"),
  nationaliteMere: Yup.string().required(
    "La nationalité de la mère est obligatoire"
  ),
});

const StepOneSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export { schemaStep1, schemaStep2, schemaStep3, schemaStep4, StepOneSchema };
