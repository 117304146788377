import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import themes from "../components/Theme.js";
import "primeicons/primeicons.css";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

import axios from "axios";

import {
  getProvinces,
  getCitiesByProvince,
  getTerritoriesByProvince,
  getSectorByTerritory,
  getCommunesByCity,
  getSexes,
  getDocuments,
  getCountries,
  getCities,
  getCommunes,
  saveRequest,
  savingReq,
  send,
} from "../services/FormService.js";

import {
  schemaStep1,
  schemaStep2,
  schemaStep3,
  schemaStep4,
  StepOneSchema,
} from "../services/ValidationService.js";

import { useToast } from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  SimpleGrid,
  Button,
  Container,
  Divider,
  Select,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Image,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Flex,
} from "@chakra-ui/react";

import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button as PrimeButton } from "primereact/button";
import { Banner } from "../components/Banner.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const RequestForm = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const stepperRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const comboProvincesx = { m: "mm", j: "m" };
  const [comboProvinces, setComboProvinces] = useState([]);
  const [comboTerritories, setComboTerritories] = useState([]);
  const [comboSectors, setComboSectors] = useState([]);
  const [comboCities, setComboCities] = useState([]);
  const [comboCommunes, setComboCommunes] = useState([]);
  const [comboDocuments, setComboDocuments] = useState([]);
  const [comboCountries, setComboCountries] = useState([]);

  /*getProvinces,
  getCitiesByProvince,
  getTerritoriesByProvince,
  getSectorByTerritory,
  getCommunesByCity,
  getSexes,
  getDocuments,*/

  //const [name, setName] = useState('');
  //const [email, setEmail] = useState('');
  const [errorsJsonStep1, setErrorsJsonStep1] = useState({});
  const [errorsJsonStep2, setErrorsJsonStep2] = useState({});
  const [errorsJsonStep3, setErrorsJsonStep3] = useState({});
  const [errorsJsonStep4, setErrorsJsonStep4] = useState({});

  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [postNom, setPostNom] = useState("");
  const [lieuNaiss, setLieuNaiss] = useState("");
  const [dateNaiss, setDateNaiss] = useState("");
  const [etatCivil, setEtatCivil] = useState("Marié");
  const [gender, setGender] = useState("1");
  const [profession, setProfession] = useState("");

  const [province, setProvince] = useState("");
  const [provinceID, setProvinceID] = useState(null);

  const [territoire, setTerritoire] = useState("");
  const [territoireID, setTerritoireID] = useState(null);

  const [secteur, setSecteur] = useState("");
  const [secteurID, setSecteurID] = useState(null);

  const [village, setVillage] = useState("");
  const [villageID, setVillageID] = useState(null);

  const [ethnie, setEthnie] = useState("");
  const [piece, setPiece] = useState("");
  const [pieceID, setPieceID] = useState(null);

  const [file, setFile] = useState("");
  const [number, setNumber] = useState("");
  const [avenue, setAvenue] = useState("");

  const [provinceAdresse, setProvinceAdresse] = useState("");
  const [provinceAdresseID, setProvinceAdresseID] = useState("");

  const [villeAdresse, setVilleAdresse] = useState("");
  const [villeAdresseID, setVilleAdresseID] = useState("");

  const [communeAdresse, setCommuneAdresse] = useState("");
  const [communeAdresseID, setCommuneAdresseID] = useState("");

  const [tel, setTel] = useState("");
  const [motif, setMotif] = useState("");

  const [pere, setPere] = useState("");
  const [nationalitePere, setNationalitePere] = useState("");
  const [nationalitePereID, setNationalitePereID] = useState("");

  const [mere, setMere] = useState("");
  const [nationaliteMere, setNationaliteMere] = useState("");
  const [nationaliteMereID, setNationaliteMereID] = useState("");

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const provinces = await getProvinces();
        console.log(provinces);
        setComboProvinces(provinces);
        console.log(comboProvinces);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const documents = await getDocuments();
        console.log("bbbbbbbbbbbbbbbbbb");
        console.log(documents);
        setComboDocuments(documents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDocuments();
  }, []);

  useEffect(() => {
    const fetchTerritoriesByProvince = async (provinceID) => {
      try {
        const territories = await getTerritoriesByProvince(provinceID);
        console.log(territories);
        setComboTerritories(territories);
        console.log("oooooooooooooooooooooooooooooooooooooo " + provinceID);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (provinceID) {
      fetchTerritoriesByProvince(provinceID); // Passez provinceID ici
    }
  }, [provinceID]);

  useEffect(() => {
    const fetchSectorByTerritory = async (territoireID) => {
      try {
        const sectors = await getSectorByTerritory(territoireID);
        console.log(sectors);
        setComboSectors(sectors);
        console.log(comboSectors);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (territoireID) {
      fetchSectorByTerritory(territoireID);
    }
  }, [territoireID]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await getCountries();
        console.log(countries);
        setComboCountries(countries);
        console.log("ccccccccccc");
        console.log(comboCountries);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchCities = async (provinceAdresseID) => {
      try {
        const cities = await getCities(provinceAdresseID);
        console.log(cities);
        setComboCities(cities);
        console.log(comboCities);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (provinceAdresseID) {
      fetchCities(provinceAdresseID);
    }
  }, [provinceAdresseID]);

  useEffect(() => {
    const fetchCommunes = async (villeAdresseID) => {
      try {
        const communes = await getCommunes(villeAdresseID);
        console.log(communes);
        setComboCommunes(communes);
        console.log(comboCommunes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (villeAdresseID) {
      fetchCommunes(villeAdresseID);
    }
  }, [villeAdresseID]);

  /*
  const getCitiesByProvince = async () => {
    try {
      const fetchedData = await getCitiesByProvince(2);
      console.log(fetchedData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  getCitiesByProvince();*/

  const goToStep2 = () => {
    const data = {
      nom: nom,
      postNom: postNom,
      prenom: prenom,
      lieuNaiss: lieuNaiss,
      dateNaiss: dateNaiss,
      etatCivil: etatCivil,
      gender: gender,
      profession: profession,
    };

    schemaStep1
      .validate(data, { strict: false, abortEarly: false })
      .then((valid) => stepperRef.current.nextCallback())
      .catch((error) => {
        const errors = error.errors;
        const fieldErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        console.log(fieldErrors);
        setErrorsJsonStep1(fieldErrors);
        console.log(fieldErrors);
        //console.log("Erreur de validation :", error);
        //console.log("Message d'erreur :", error.message);
        //console.log("Détails des erreurs :", error.details);
        //console.dir(error);
      });

    //
  };

  const goToStep3 = () => {
    const data = {
      province: province,
      territoire: territoire,
      secteur: secteur,
      village: village,
      ethnie: ethnie,
      piece: piece,
    };

    schemaStep2
      .validate(data, { strict: false, abortEarly: false })
      .then((valid) => {
        stepperRef.current.nextCallback();
        console.log(valid);
      })
      .catch((error) => {
        const errors = error.errors;
        const fieldErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        console.log(fieldErrors);
        setErrorsJsonStep2(fieldErrors);
        console.log("dddrrrruuuuu");
        console.log(fieldErrors);
        //console.log("Erreur de validation :", error);
        //console.log("Message d'erreur :", error.message);
        //console.log("Détails des erreurs :", error.details);
        //console.dir(error);
      });

    //
  };

  const goToStep4 = () => {
    const data = {
      file: file,
      number: number,
      avenue: avenue,
      provinceAdresse: provinceAdresse,
      villeAdresse: villeAdresse,
      communeAdresse: communeAdresse,
      tel: tel,
      motif: motif,
    };

    schemaStep3
      .validate(data, { strict: false, abortEarly: false })
      .then((valid) => stepperRef.current.nextCallback())
      .catch((error) => {
        const errors = error.errors;
        const fieldErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        console.log(fieldErrors);
        setErrorsJsonStep3(fieldErrors);
        console.log(fieldErrors);
        //console.log("Erreur de validation :", error);
        //console.log("Message d'erreur :", error.message);
        //console.log("Détails des erreurs :", error.details);
        //console.dir(error);
      });

    //
  };

  const goToReview = () => {
    const data = {
      pere: pere,
      nationalitePere: nationalitePere,
      mere: mere,
      nationaliteMere: nationaliteMere,
    };

    schemaStep4
      .validate(data, { strict: false, abortEarly: false })
      .then((valid) => {
        onOpen();
      })
      .catch((error) => {
        const errors = error.errors;
        const fieldErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        console.log(fieldErrors);
        setErrorsJsonStep4(fieldErrors);
        console.log(fieldErrors);
        //console.log("Erreur de validation :", error);
        //console.log("Message d'erreur :", error.message);
        //console.log("Détails des erreurs :", error.details);
        //console.dir(error);
      });

    //
  };

  const submitForm = async () => {
    const data = {
      nom: nom,
      postNom: postNom,
      prenom: prenom,
      lieuNaiss: lieuNaiss,
      dateNaiss: dateNaiss,
      etatCivil: etatCivil,
      gender: gender,
      profession: profession,
      provinceID: provinceID,
      territoireID: territoireID,
      secteurID: secteurID,
      village: village,
      ethnie: ethnie,
      pieceID: pieceID,
      file: file,
      number: number,
      avenue: avenue,
      provinceAdresseID: provinceAdresseID,
      villeAdresseID: villeAdresseID,
      communeAdresseID: communeAdresseID,
      tel: tel,
      motif: motif,
      pere: pere,
      nationalitePereID: nationalitePereID,
      mere: mere,
      nationaliteMereID: nationaliteMereID,
    };

    //console.log("Voici l'image", data.file, file);
    const response = await send(data);
    if (response) {
      const date = new Date(Date.now());
      const formattedDate = `${date
        .getFullYear()
        .toString()
        .slice(-2)}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
        date.getDate()
      ).padStart(2, "0")}`;
      response.issueDate = formattedDate;
      console.log("Voi:", response);

      navigate("/note", { state: response });
    } else {
      console.log(response);
      toast({
        title: "Erreur",
        description:
          "Impossible d'envoyer les données. Veuillez verifier les données et réessayer",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }

    //console.log(response);
    //Ici on envoie dans la base de données

    //navigate("/note", { state: data });
  };

  return (
    <div>
      {/*  <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
        }}
        validationSchema={StepOneSchema}
        onSubmit={(values) => {
          // same shape as initial values
          console.log(values);
        }}
      >
        {({ errors, touched, handleSubmit }) => (
          <Form>
            <Field name="firstName" />
            {errors.firstName && touched.firstName ? (
              <div style={{color:"red"}}>{errors.firstName}</div>
            ) : null}
            <Field name="lastName" />
            {errors.lastName && touched.lastName ? (
              <div style={{color:"red"}}>{errors.lastName}</div>
            ) : null}
            <Field name="email" type="email" />
            {errors.email && touched.email ? <div style={{color:"red"}}>{errors.email}</div> : null}
            <button onClick={handleSubmit}>Submit</button>
          </Form>
        )}
      </Formik>*/}

      <Banner />
      <Box pb={100} style={{ display: "flex", justifyContent: "center" }}>
        <Box w={800}>
          <Stepper
            ref={stepperRef}
            linear
            /**</Box>orientation="vertical"*/
          >
            <StepperPanel>
              <Box>
                <Text
                  fontSize="2xl"
                  fontFamily={themes.fonts.body}
                  textAlign={"center"}
                >
                  INFORMATIONS PERSONELLES SUR LE DEMANDEUR
                </Text>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Nom</FormLabel>
                  <Input
                    variant="filled"
                    className="nom"
                    type="text"
                    size="md"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep1.nom}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Post-nom
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="postNom"
                    type="email"
                    size="md"
                    value={postNom}
                    onChange={(e) => setPostNom(e.target.value)}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep1.postNom}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Prénom</FormLabel>
                  <Input
                    variant="filled"
                    className="prenom"
                    type="email"
                    size="md"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep1.prenom}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Né a</FormLabel>
                  <Input
                    variant="filled"
                    className="lieuNaiss"
                    type="email"
                    size="md"
                    value={lieuNaiss}
                    onChange={(e) => setLieuNaiss(e.target.value)}
                  />
                  <div style={{ color: "red" }}>
                    {errorsJsonStep1.leiuNaiss}
                  </div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Le</FormLabel>
                  <Input
                    variant="filled"
                    className="dateNaiss"
                    type="date"
                    size="md"
                    value={dateNaiss}
                    onChange={(e) => setDateNaiss(e.target.value)}
                  />
                  <div style={{ color: "red" }}>
                    {errorsJsonStep1.dateNaiss}
                  </div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Etat civil
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="etatCivil"
                    size="md"
                    onChange={(e) => setEtatCivil(e.target.value)}
                  >
                    <option value="Célibataire" default>
                      Célibataire
                    </option>
                    <option value="Marié">Marié</option>
                    <option value="Divorcé">Divorcé</option>
                    <option value="Veuf">Veuf</option>
                  </Select>
                  <div style={{ color: "red" }}>
                    {errorsJsonStep1.etatCivil}
                  </div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Sexe</FormLabel>
                  <Select
                    variant="filled"
                    className="gender"
                    size="md"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option defaultChecked value="1">
                      M
                    </option>
                    <option value="2">F</option>
                  </Select>
                  <div style={{ color: "red" }}>{errorsJsonStep1.gender}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Profession
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="profession"
                    type="email"
                    size="md"
                    value={profession}
                    onChange={(e) => setProfession(e.target.value)}
                  />
                  <div style={{ color: "red" }}>
                    {errorsJsonStep1.profession}
                  </div>
                </FormControl>
              </Box>
              <Container centerContent fontFamily={themes.fonts.body}>
                <Box style={{ flexDirection: "row" }}>
                  <Button
                    colorScheme="blue"
                    bgColor={"#0095c9"}
                    _hover={{ bgColor: "#db3832" }}
                    size="md"
                    fontFamily={themes.fonts.body}
                    onClick={
                      () => goToStep2() /*stepperRef.current.nextCallback()*/
                    }
                    top={10}
                  >
                    Suivant
                    <GrLinkNext />
                  </Button>
                </Box>
              </Container>
            </StepperPanel>

            <StepperPanel>
              <Text
                fontSize="2xl"
                fontFamily={themes.fonts.body}
                textAlign={"center"}
              >
                ORIGINES DU DEMANDEUR
              </Text>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Province de
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="province"
                    type="email"
                    size="md"
                    value={province}
                    onChange={(e) => {
                      const selectedProvince = comboProvinces.find(
                        (prov) => prov.name === e.target.value
                      );
                      setProvince(e.target.value);
                      setProvinceID(selectedProvince.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboProvinces.map((province) => (
                      <option key={province.id} value={province.name}>
                        {province.name}
                      </option>
                    ))}
                  </Select>
                  <div style={{ color: "red" }}>{errorsJsonStep2.province}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Térritoire de
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="territoire"
                    type="email"
                    size="md"
                    value={territoire}
                    onChange={(e) => {
                      const selectedTerritory = comboTerritories.find(
                        (terr) => terr.name === e.target.value
                      );
                      setTerritoire(e.target.value);
                      setTerritoireID(selectedTerritory.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboTerritories.length > 0 ? (
                      comboTerritories.map((territory) => (
                        <option key={territory.id} value={territory.name}>
                          {territory.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        Selectionner
                      </option>
                    )}
                  </Select>
                  <div style={{ color: "red" }}>
                    {errorsJsonStep2.territoire}
                  </div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Secteur de
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="secteur"
                    type="email"
                    size="md"
                    value={secteur}
                    onChange={(e) => {
                      const selectedSector = comboSectors.find(
                        (sect) => sect.name === e.target.value
                      );
                      setSecteur(e.target.value);
                      setSecteurID(selectedSector.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboSectors.length > 0 ? (
                      comboSectors.map((sector) => (
                        <option key={sector.id} value={sector.name}>
                          {sector.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        Selectionner
                      </option>
                    )}
                  </Select>
                  <div style={{ color: "red" }}>{errorsJsonStep2.secteur}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Village de
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="village"
                    type="email"
                    size="sm"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep2.village}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Ethnie</FormLabel>
                  <Input
                    variant="filled"
                    className="ethnie"
                    type="email"
                    size="sm"
                    value={ethnie}
                    onChange={(e) => setEthnie(e.target.value)}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep2.ethnie}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Pièce justificatives en guise de soubassement
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="piece"
                    type="email"
                    size="sm"
                    value={piece}
                    onChange={(e) => {
                      const selectedDocument = comboDocuments.find(
                        (doc) => doc.description === e.target.value
                      );
                      setPiece(e.target.value);
                      setPieceID(selectedDocument.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboDocuments.length > 0 ? (
                      comboDocuments.map((doc) => (
                        <option key={doc.id} value={doc.description}>
                          {doc.description}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        Selectionner
                      </option>
                    )}
                  </Select>
                  <div style={{ color: "red" }}>{errorsJsonStep2.piece}</div>
                </FormControl>
              </Box>
              <Container centerContent fontFamily={themes.fonts.body}>
                <Box style={{ flexDirection: "row" }}>
                  <Button
                    colorScheme="blue"
                    bgColor={"#0095c9"}
                    _hover={{ bgColor: "#db3832" }}
                    size="md"
                    onClick={() => stepperRef.current.prevCallback()}
                    top={10}
                    m={2}
                  >
                    <GrLinkPrevious />
                    Précédent
                  </Button>
                  <Button
                    colorScheme="blue"
                    bgColor={"#0095c9"}
                    _hover={{ bgColor: "#db3832" }}
                    size="md"
                    fontFamily={themes.fonts.body}
                    onClick={() => goToStep3()}
                    top={10}
                    m={2}
                  >
                    Suivant
                    <GrLinkNext />
                  </Button>
                </Box>
              </Container>
            </StepperPanel>

            <StepperPanel>
              <Text
                fontSize="2xl"
                fontFamily={themes.fonts.body}
                textAlign={"center"}
              >
                PIECE JUSTIFICATIVE ET ADRESSE PHYSIQUE
              </Text>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Importer le fichier
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="file"
                    type="file"
                    size="md"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep3.file}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Residant au no.
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="number"
                    type="email"
                    size="md"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </FormControl>
                <div style={{ color: "red" }}>{errorsJsonStep3.number}</div>
              </Box>

              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Avenue</FormLabel>
                  <Input
                    variant="filled"
                    className="avenue"
                    type="email"
                    size="md"
                    value={avenue}
                    onChange={(e) => setAvenue(e.target.value)}
                  />
                </FormControl>
                <div style={{ color: "red" }}>{errorsJsonStep3.avenue}</div>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Province
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="provinceAdresse"
                    type="email"
                    size="md"
                    value={provinceAdresse}
                    onChange={(e) => {
                      const selectedProvince = comboProvinces.find(
                        (prov) => prov.name === e.target.value
                      );
                      setProvinceAdresse(e.target.value);
                      setProvinceAdresseID(selectedProvince.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboProvinces.map((prov) => (
                      <option key={prov.id} value={prov.name}>
                        {prov.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ color: "red" }}>
                  {errorsJsonStep4.provinceAdresse}
                </div>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Ville</FormLabel>
                  <Select
                    variant="filled"
                    className="villeAdresse"
                    type="email"
                    size="md"
                    value={villeAdresse}
                    onChange={(e) => {
                      const selectedCity = comboCities.find(
                        (prov) => prov.name === e.target.value
                      );
                      setVilleAdresse(e.target.value);
                      setVilleAdresseID(selectedCity.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboCities.map((city) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </Select>
                  <div style={{ color: "red" }}>
                    {errorsJsonStep4.villeAdresse}
                  </div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Commune</FormLabel>
                  {/*<Input
                    variant="filled"
                    className="communeAdresse"
                    type="email"
                    size="md"
                    value={communeAdresse}
                    onChange={(e) => setCommuneAdresse(e.target.value)}
                  />  */}
                  <Select
                    variant="filled"
                    className="communeAdresse"
                    type="email"
                    size="md"
                    value={communeAdresse}
                    onChange={(e) => {
                      const selectedCommune = comboCommunes.find(
                        (comm) => comm.name === e.target.value
                      );
                      setCommuneAdresse(e.target.value);
                      setCommuneAdresseID(selectedCommune.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboCommunes.map((com) => (
                      <option key={com.id} value={com.name}>
                        {com.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ color: "red" }}>
                  {errorsJsonStep3.communeAdresse}
                </div>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl id="phone">
                  <FormLabel fontSize={themes.fontSize.body}>
                    Téléphone
                  </FormLabel>
                  <PhoneInput
                    variant="filled"
                    className="tel"
                    country={"cd"}
                    value={tel}
                    size="md"
                    onChange={(phone) => setTel(phone)}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "4px",
                      borderColor: "#E2E8F0",
                    }}
                    buttonStyle={{
                      borderColor: "#E2E8F0",
                    }}
                    dropdownStyle={{
                      borderColor: "#E2E8F0",
                    }}
                    backgroundColor="gray.100"
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep4.tel}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>Motif</FormLabel>
                  <Input
                    variant="filled"
                    className="motif"
                    type="email"
                    size="md"
                    value={motif}
                    onChange={(e) => setMotif(e.target.value)}
                  />
                </FormControl>
                <div style={{ color: "red" }}>{errorsJsonStep4.motif}</div>
              </Box>
              <Container centerContent fontFamily={themes.fonts.body}>
                <Box style={{ flexDirection: "row" }}>
                  <Button
                    colorScheme=""
                    bgColor={"#0095c9"}
                    _hover={{ bgColor: "#db3832" }}
                    size="md"
                    onClick={() => stepperRef.current.prevCallback()}
                    top={10}
                    m={2}
                  >
                    <GrLinkPrevious />
                    Précédent
                  </Button>
                  <Button
                    colorScheme="blue"
                    bgColor={"#0095c9"}
                    _hover={{ bgColor: "#db3832" }}
                    size="md"
                    fontFamily={themes.fonts.body}
                    onClick={
                      () => goToStep4() /*stepperRef.current.nextCallback()*/
                    }
                    top={10}
                    m={2}
                  >
                    Suivant
                    <GrLinkNext />
                  </Button>
                </Box>
              </Container>
            </StepperPanel>

            <StepperPanel header="">
              <Text
                fontSize="2xl"
                fontFamily={themes.fonts.body}
                textAlign={"center"}
              >
                INFORMATIONS SUR LES PARENTS DU DEMANDEUR
              </Text>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Fils, Fille de (Père)
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="pere"
                    type="email"
                    size="md"
                    value={pere}
                    onChange={(e) => setPere(e.target.value)}
                  />
                </FormControl>
                <div style={{ color: "red" }}>{errorsJsonStep4.pere}</div>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    De nationalité
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="nationalitePere"
                    type="email"
                    size="md"
                    value={nationalitePere}
                    onChange={(e) => {
                      const selectedNationality = comboCountries.find(
                        (sn) => sn.name === e.target.value
                      );
                      setNationalitePere(e.target.value);
                      setNationalitePereID(selectedNationality.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboCountries.length > 0 ? (
                      comboCountries.map((country) => (
                        <option key={country.id} value={country.name}>
                          {country.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        Selectionner
                      </option>
                    )}
                  </Select>
                  <div style={{ color: "red" }}>
                    {errorsJsonStep4.nationalitePere}
                  </div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    Fils, Fille de (Mère)
                  </FormLabel>
                  <Input
                    variant="filled"
                    className="mere"
                    type="email"
                    size="md"
                    value={mere}
                    onChange={(e) => setMere(e.target.value)}
                  />
                  <div style={{ color: "red" }}>{errorsJsonStep4.mere}</div>
                </FormControl>
              </Box>
              <Box fontFamily={themes.fonts.body}>
                <FormControl>
                  <FormLabel fontSize={themes.fontSize.body}>
                    de nationalité
                  </FormLabel>
                  <Select
                    variant="filled"
                    className="nationaliteMere"
                    type="email"
                    size="md"
                    value={nationaliteMere}
                    onChange={(e) => {
                      const selectedNationality = comboCountries.find(
                        (sn) => sn.name === e.target.value
                      );
                      setNationaliteMere(e.target.value);
                      setNationaliteMereID(selectedNationality.id);
                    }}
                  >
                    <option value="" disabled>
                      Selectionner
                    </option>
                    {comboCountries.length > 0 ? (
                      comboCountries.map((country) => (
                        <option key={country.id} value={country.name}>
                          {country.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        Selectionner
                      </option>
                    )}
                  </Select>
                  <div style={{ color: "red" }}>
                    {errorsJsonStep4.nationaliteMere}
                  </div>
                </FormControl>
              </Box>

              <Container centerContent fontFamily={themes.fonts.body}>
                <Box style={{ flexDirection: "row" }}>
                  <Button
                    colorScheme="blue"
                    bgColor={"#0095c9"}
                    _hover={{ bgColor: "#db3832" }}
                    size="md"
                    onClick={() => stepperRef.current.prevCallback()}
                    top={10}
                  >
                    <GrLinkPrevious />
                    Précédent
                  </Button>
                </Box>
                <Button
                  colorScheme="blue"
                  bgColor={"#0095c9"}
                  _hover={{ bgColor: "#db3832" }}
                  size="md"
                  fontFamily={themes.fonts.body}
                  onClick={() => goToReview()}
                  top={10}
                  marginTop={15}
                >
                  Visualiser avant de soumettre
                </Button>
              </Container>
            </StepperPanel>
          </Stepper>
        </Box>
        {/*  <Button onClick={onOpen}>Open Modal</Button> */}
        <Modal
          size={"lg"}
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader style={{ textAlign: "center" }}>
              VISUALISER VOS INFORMATIONS AVANT LA SOUMISSION
            </ModalHeader>
            <Box style={{ display: "flex" }}>
              <Divider flex="1" borderColor="blue" borderWidth={6} />
              <Divider flex="1" borderColor="red" borderWidth={6} />
              <Divider flex="1" borderColor="yellow" borderWidth={6} />
            </Box>
            <ModalCloseButton />
            <ModalBody pb={6} fontSize={20}>
              <SimpleGrid columns={[2, null, 2]} spacing="40px">
                <Box>
                  <h2>IDENTITE</h2>
                  Nom:{prenom} {nom} {postNom} <br />
                  Lieu de naissance: {lieuNaiss}
                  <br />
                  Date de naissance: {dateNaiss}
                  <br />
                  Etat civil: {etatCivil}
                  <br />
                  Sexe : {gender == "1" ? "M" : gender == "2" ? "F" : ""}
                  <br />
                  Profession: {profession}
                  <br />
                </Box>
                <Box>
                  <h2>ORIGINES</h2>
                  Province: {province}
                  <br />
                  Territoire: {territoire}
                  <br />
                  Secteur: {secteur}
                  <br />
                  Village: {village}
                  <br />
                  Ethnie: {ethnie}
                </Box>
                <Box>
                  <h2>ADRESSE</h2>
                  Adresse (Numero): {number}
                  <br />
                  Avenue: {avenue}
                  <br />
                  Province: {provinceAdresse}
                  <br />
                  Ville: {villeAdresse}
                  <br />
                  Commune: {communeAdresse}
                  <br />
                  Téléphone: {tel}
                  <br />
                  Motif: {motif}
                  <br />
                </Box>
                <Box>
                  <h2>PARENTS</h2>
                  Nom du père: {pere}
                  <br />
                  Nationalié du père : {nationalitePere}
                  <br />
                  Nom de la mère : {mere}
                  <br />
                  Nationalité de la mère: {nationaliteMere}
                  <br />
                  <br />
                </Box>
                Montant à payer : 140 000 CDF
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button
                bgColor={"#0095c9"}
                _hover={{ bgColor: "#db3832" }}
                onClick={() => submitForm()}
                colorScheme="blue"
                mr={3}
              >
                Soumettre
              </Button>
              <Button onClick={onClose}>Revenir au formulaire</Button>
            </ModalFooter>
            <Box style={{ display: "flex" }}>
              <Divider flex="1" borderColor="blue" borderWidth={6} />
              <Divider flex="1" borderColor="red" borderWidth={6} />
              <Divider flex="1" borderColor="yellow" borderWidth={6} />
            </Box>
          </ModalContent>
        </Modal>
      </Box>

      <Flex
        direction={{ base: "column", md: "row" }}
        p={4}
        justifyContent="space-around"
        alignItems="center"
        backgroundImage={"/pj2.jpg"}
      >
        <Box
          w={{ base: "100%", md: "30%" }}
          color="white"
          p={4}
          m={2}
          borderRadius="md"
          textAlign="center"
        >
          <Text fontWeight="bold">
            {/*  © Ministère de la Justice et Garde des Sceaux, 2024 */}
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Image height={120} src="./minjustice.png" />
            </Box>
          </Text>
          <Text></Text>
        </Box>
        <Box
          w={{ base: "100%", md: "30%" }}
          color="white"
          p={4}
          m={2}
          borderRadius="md"
          textAlign="center"
        >
          <Text fontWeight="bold">Téléphone</Text>
          <Text>+243 81 41 68 325 </Text>
        </Box>
        <Box
          w={{ base: "100%", md: "30%" }}
          color="white"
          p={4}
          m={2}
          borderRadius="md"
          textAlign="center"
        >
          <Text fontWeight="bold">Email</Text>
          <Text>info@justice.gouv.cd</Text>
        </Box>
      </Flex>
    </div>
  );
};
