import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const getProvinces = async () => {
  try {
    const provinces = await axios.get(
      "https://beta-cncapi.flexpay.cd/api/rest/v1/provinces"
    );
    return provinces.data;
  } catch (error) {
    return [];
  }
};

const getCitiesByProvince = async (provinceId) => {
  try {
    const cities = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/cities/${provinceId}`
    );
    return cities;
  } catch (error) {
    return {};
  }
};

const getTerritoriesByProvince = async (provinceId) => {
  try {
    const territories = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/territories/${provinceId}`
    );
    return territories.data;
  } catch (error) {
    return {};
  }
};

const getSectorByTerritory = async (territoireId) => {
  try {
    const sectors = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/sectors/${territoireId}`
    );
    return sectors.data;
  } catch (error) {
    return {};
  }
};

const getCommunesByCity = async (cityId) => {
  try {
    const communes = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/communes/${cityId}`
    );
    return communes.data;
  } catch (error) {
    return {};
  }
};

const getSexes = async () => {
  try {
    const sexes = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/sexes`
    );
    return sexes.data;
  } catch (error) {
    return {};
  }
};

const getDocuments = async () => {
  try {
    const documents = await axios.get(
      "https://beta-cncapi.flexpay.cd/api/rest/v1/documents"
    );
    return documents.data;
  } catch (error) {
    return {};
  }
};

const getCountries = async () => {
  try {
    const countries = await axios.get(
      "https://beta-cncapi.flexpay.cd/api/rest/v1/countries"
    );
    return countries.data;
  } catch (error) {
    return [];
  }
};

const getCities = async (provinceID) => {
  try {
    const cities = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/cities/${provinceID}`
    );
    return cities.data;
  } catch (error) {
    return [];
  }
};

const getCommunes = async (villeID) => {
  try {
    const cities = await axios.get(
      `https://beta-cncapi.flexpay.cd/api/rest/v1/communes/${villeID}`
    );
    return cities.data;
  } catch (error) {
    return [];
  }
};

function fileToBlob(file) {
  return new Promise((resolve, reject) => {
    const blob = new Blob([file], { type: file.type });
    resolve(blob);
  });
}

const saveRequest = async (data) => {
  try {
    // Créer un objet FormData
    const formData = new FormData();

    // Ajouter le fichier image à FormData
    formData.append("image", data.file); // Assurez-vous que 'image' est un Blob ou File
    console.log("Verification de l'image ", data.file);

    // Ajouter les données du document à FormData en tant que chaîne JSON

    const documentData = `{
      "firstName": "MARIE-ROSE",
      "postName": "BARAKA",
      "surName": "NGONGO",
      "birthPlace": "Kinshasa",
      "birthDate": "24-12-2001",
      "maritalStatus": "Célibataire",
      "sex": "1",
      "profession": "Informaticien",
      "province": "17",
      "territory": "34",
      "sector": "99",
      "village": "Mupompa",
      "ethnic": "luba",
      "identity": "1",
      "livingStreetNumber": "17",
      "livingStreet": "Safoutier",
      "livingProvince": "1",
      "livingCity": "1",
      "livingTownship": "1",
      "phone": "243815877848",
      "reason": "Mariage",
      "fatherName": "NGONGO",
      "motherName": "BARAKA",
      "fatherNationality": "1",
      "motherNationality": "1"
    }`;

    /*const documentData = `{
      "firstName": "${data.nom}",
      "postName": "${data.postNom}",
      "surName": "NGONGO",
      "birthPlace": "${data.lieuNaiss}",
      "birthDate": "${data.dateNaiss}",
      "maritalStatus": "${data.etatCivil}",
      "sex": "${data.gender}",
      "profession": "${data.profession}",
      "province": "${data.provinceID}",
      "territory": "${data.territoireID}",
      "sector": "${data.secteurID}",
      "village": "${data.village}",
      "ethnic": "${data.ethnie}",
      "identity": "${data.pieceID}",
      "livingStreetNumber": "${data.number}",
      "livingStreet": "${data.avenue}",
      "livingProvince": "${data.provinceAdresse}",
      "livingCity": "${data.villeAdresseID}",
      "livingTownship": "${data.communeAdresseID}",
      "phone": "${data.tel}",
      "reason": "${data.motif}",
      "fatherName": "${data.pere}",
      "motherName": "${data.mere}",
      "fatherNationality": ${data.nationalitePereID},
      "motherNationality": "${data.nationaliteMereID}"
    }`;*/

    //{"firstName": "MARIE-ROSE","postName": "BARAKA","surName": "NGONGO","birthPlace": "Kinshasa","birthDate": "24-12-2001","maritalStatus": "Célibataire","sex": "1","profession": "Informaticien","province": "17","territory": "34","sector": "99","village": "Mupompa","ethnic": "luba","identity": "1","livingStreetNumber": "17","livingStreet": "Safoutier","livingProvince": "1","livingCity": "1","livingTownship": "1","phone": "243815877848","reason": "Mariage","fatherName": "NGONGO","motherName": "BARAKA","fatherNationality": 1,"motherNationality": "1"}

    /*const documentData = {
      firstName: data.nom,
      postName: data.postNom,
      surName: "NGONGO",
      birthPlace: data.lieuNaiss,
      birthDate: data.dateNaiss,
      maritalStatus: data.etatCivil,
      sex: data.gender,
      profession: data.profession,
      province: data.provinceID,
      territory: data.territoireID,
      sector: data.secteurID,
      village: data.village,
      ethnic: data.ethnie,
      identity: data.pieceID,
      livingStreetNumber: data.number,
      livingStreet: data.avenue,
      livingProvince: data.provinceAdresse,
      livingCity: data.villeAdresseID,
      livingTownship: data.communeAdresseID,
      phone: data.tel,
      reason: data.motif,
      fatherName: data.pere,
      motherName: data.mere,
      fatherNationality: data.nationalitePereID,
      motherNationality: data.nationaliteMereID,
    };*/
    console.log("Voici les données", documentData);

    formData.append("document", documentData);

    // Envoyer la requête POST avec Axios
    const response = await axios.post(
      "https://beta-cncapi.flexpay.cd/api/rest/v1/document/save",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Content-Type": "application/json", // Ajouter le bon type de contenu
        },
      }
    );

    // console.log("Voici les données envoyées", documentData);
    return response;
  } catch (error) {
    console.error("Erreur lors de la requête:", error);
    throw error; // Il est bon de relancer l'erreur pour la gestion future
  }
};

const savingReq = async (data) => {
  try {
    const response = await axios.post(
      "https://beta-cncapi.flexpay.cd/api/rest/v1/document/save",
      {
        document: {
          firstName: data.nom,
          postName: data.postNom,
          surName: "NGONGO",
          birthPlace: data.lieuNaiss,
          birthDate: data.dateNaiss,
          maritalStatus: data.etatCivil,
          sex: data.gender,
          profession: data.profession,
          province: data.provinceID,
          territory: data.territoireID,
          sector: data.secteurID,
          village: data.village,
          ethnic: data.ethnie,
          identity: data.pieceID,
          livingStreetNumber: data.number,
          livingStreet: data.avenue,
          livingProvince: data.provinceAdresse,
          livingCity: data.villeAdresseID,
          livingTownship: data.communeAdresseID,
          phone: data.tel,
          reason: data.motif,
          fatherName: data.pere,
          motherName: data.mere,
          fatherNationality: data.nationalitePereID,
          motherNationality: data.nationaliteMereID,
        },
        image: data.image,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
};

const send = async (data) => {
  try {
    var formData = new FormData();

    formData.append("image", data.file);
    formData.append(
      "document",
      new Blob(
        [
          JSON.stringify({
            firstName: data.nom,
            postName: data.postNom,
            surName: data.prenom,
            birthPlace: data.lieuNaiss,
            birthDate: data.dateNaiss,
            maritalStatus: data.etatCivil,
            sex: data.gender,
            profession: data.profession,
            province: data.provinceID,
            territory: data.territoireID,
            sector: data.secteurID,
            village: data.village,
            ethnic: data.ethnie,
            identity: data.pieceID,
            livingStreetNumber: data.number,
            livingStreet: data.avenue,
            livingProvince: data.provinceAdresseID,
            livingCity: data.villeAdresseID,
            livingTownship: data.communeAdresseID,
            phone: data.tel,
            reason: data.motif,
            fatherName: data.pere,
            motherName: data.mere,
            fatherNationality: data.nationalitePereID,
            motherNationality: data.nationaliteMereID,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    const response = await axios.post(
      "https://beta-cncapi.flexpay.cd/api/rest/v1/document/save",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status !== 200) {
      //alert("There was an error!");
      return false;
    } else {
      //alert("Request successful");
      console.log("fff", response.data);
      return response.data;
    }
  } catch (err) {
    return false;
  }
};

export {
  getProvinces,
  getCitiesByProvince,
  getTerritoriesByProvince,
  getSectorByTerritory,
  getCommunesByCity,
  getSexes,
  getDocuments,
  getCountries,
  getCities,
  getCommunes,
  saveRequest,
  savingReq,
  send,
};
